export const data = {
  Завтраки: [
    {
      name: "Сырники со сметаной и джемом",
      serving: "180 / 30 / 30 г",
      price: 310,
    },
    {
      name: "Яйцо пашот на чиабатте с лососем и муссом из авокадо",
      serving: "200 г",
      price: 720,
    },
    {
      name: "Яичница с беконом",
      serving: "120 / 50 / 30 г",
      price: 250,
    },
    {
      name: "Омлет с сыром",
      serving: "150 / 50 г",
      price: 240,
    },
    {
      name: "Омлет с овощами",
      serving: "150 / 80 г",
      price: 200,
    },
    {
      name: "Каша овсяная с сухофруктами",
      serving: "250 / 30 г",
      price: 180,
    },    
    {
      name: "Блины",
      description: "(сметана / сгущенное молоко)",
      serving: "3 шт / 30 г",
      price: 150,
    },
    {
      name: "Блины",
      description: "(мёд / варенье)",
      serving: "3 шт / 30 г",
      price: 200,
    },
    {
      name: "Блины с ветчиной и сыром",
      serving: "2 шт / 30 г",
      price: 250,
    },
    {
      name: "Омлет с ветчиной",
      serving: "150 / 50 г",
      price: 280,
    },
    {
      name: "Яйцо пашот на чиабатте с прошутто и трюфельным муссом",
      serving: "160 г",
      price: 430,
    }  
  ],
  "Холодные закуски": [
    {
      name: "Брускетта с лососем и муссом из авокадо",
      serving: "2 шт",
      price: 790,
    },
    {
      name: "Сельдь с отварным картофелем и маринованным луком",
      serving: "100 / 100 / 30 г",
      price: 320,
    },
    {
      name: "Брускетта с прошутто и трюфельным муссом",
      serving: "2 шт",
      price: 480,
    },
    {
      name: "Брынза с зеленью",
      serving: "150 / 30 г",
      price: 230,
    },
    {
      name: "Сырное плато",
      description: "(дорблю, пармезан, фета, маасдам, мёд, орехи)",
      serving: "200 / 40 / 30 / 30 г",
      price: 760,
    },
    {
      name: "Бастурма",
      serving: "100 г",
      price: 420,
    },
    {
      name: "Мясное плато",
      description: "(прошутто / бастурма / салями / колбаса московская, отварной язык)",
      serving: "250 / 30 г",
      price: 930,
    },
    {
      name: "Сёмга малосольная",
      serving: "100 г",
      price: 650,
    },
    {
      name: "Овощное ассорти",
      serving: "400 г",
      price: 380,
    },
    {
      name: "Разносолы",
      description: "(помидор / огурец / капуста квашеная / перец цицак)",
      serving: "400 г",
      price: 370,
    },
    {
      name: "Маслины / оливки",
      serving: "100 г",
      price: 300,
    },
    {
      name: "Сало с гренками",
      serving: "120 / 50 / 30 г",
      price: 350,
    }
  ],
    "Горячие закуски": [
    {
      name: "Сырные палочки с кисло-сладким соусом",
      serving: "120 / 30 г",
      price: 250,
    },
    {
      name: "Наггетсы с кисло-сладким соусом",
      serving: "5 шт / 30 г",
      price: 370,
    },
    {
      name: "Лаваш с сулугуни и овощами",
      serving: "350 г",
      price: 390,
    },
    {
      name: "Язык запеченный с грибами и сыром",
      serving: "80 / 50 / 50 г",
      price: 530,
    },
    {
      name: "Мидии в сливочном соусе",
      serving: "500 / 50 / 25 г",
      price: 630,
    },
    {
      name: "Мидии в томатном соусе",
      serving: "500 / 50 / 25 г",
      price: 630,
    },
    {
      name: "Рапаны в хрустящей панировке с соусом тар-тар",
      serving: "150 / 30 / 35 г",
      price: 530,
    },
    {
      name: "Гребешок с чесноком и петрушкой в сковороде",
      description: "(подается с фокачча)",
      serving: "200 г",
      price: 1200,
    }, 
    {
      name: "Мидии в сливках, запечённые под сыром",
      description: "(мидии, лук, сливки, помидор, сыр)",
      serving: "300 г",
      price: 550,
    },
    {
      name: "Рапан в сливочном соусе",
      serving: "200 г",
      price: 550,
    },
    {
      name: "Креветки с перцем чили и розмарином",
      description: "(тигровая креветка в панцире, чеснок, перец чили, розмарин, лимон)",
      serving: "220 / 25 г",
      price: 1200,
    }
  ],
  Салаты: [
    {
      name: "Салат Аджапсандал",
      description:
        "(баклажан, помидор, перец болгарский, зелень, фета, заправка",
      serving: "250 г",
      price: 420,
    },
    {
      name: "Салат Греческий",
      description:
        "(огурец, помидор, перец болгарский, лук ялтинский, оливки, фета, руккола, оливковое масло)",
      serving: "300 г",
      price: 390,
    },
    {
      name: "Салат Дымок",
      description:
        "(запеченные овощи на мангале, зелень, чеснок, растительное масло, кинза)",
      serving: "250 г",
      price: 420,
    },
    {
      name: "Цезарь с курицей",
      description:
        "(айсберг, черри, яйцо перепелиное, курица, гренки, соус цезарь, пармезан)",
      serving: "300 г",
      price: 470,
    },
    {
      name: "Салат с телятиной су-вид и кисло-сладким соусом",
      description:
        "(телятина, микс салата, перец болгарский, помидор черри, огурец, кинза, соус чимичури)",
      serving: "270 г",
      price: 580,
    },
    {
      name: "Салат с хрустящим баклажаном и сыром",
      description:
        "(баклажан, специи, сыр)",
      serving: "270 г",
      price: 460,
    },
    {
      name: "Цезарь с креветкой",
      description:
        "(айсберг, черри, яйцо перепелиное, креветки, гренки, соус цезарь, пармезан)",
      serving: "300 г",
      price: 580,
    },
    {
      name: "Салат из крымских овощей",
      description:
        "(микс салата, помидор, огурец, болгарский перец, лук ялтинский, заправка)",
      serving: "300 г",
      price: 280,
    },
    {
      name: "Салат с говядиной и вялеными помидорами",
      description:
        "(говядина, вяленые помидоры, соус горчичный, моцарелла, микс салата, черри, чеснок)",
      serving: "250 г",
      price: 570,
    },
    {
      name: "Салат Буржуй",
      description:
        "(говядина, курица, язык, грибы, микс салата, лук ялтинский, бальзамический соус)",
      serving: "270 г",
      price: 720,
    },
    {
      name: "Салат с лососем и гуакомоле",
      description: "(микс салата, лосось, помидор черри, гуакомоле, пармезан)",
      serving: "250 г",
      price: 790,
    },
    {
      name: "Салат Морской",
      description:
        "(мидии, кальмар, креветка, микс салата, сливки)",
      serving: "250 г",
      price: 590,
    },
    {
      name: "Салат с креветкой и авокадо",
      description:
        "(микс салата, креветка тигровая, авокадо, помидор черри, бальзамический соус)",
      serving: "250 г",
      price: 680,
    }
  ],
"Первые блюда": [
    {
      name: "Том-Ям с креветкой",
      serving: "350 г",
      price: 530,
    },
    {
      name: "Суп-лапша с курицей и перепелиным яйцом",
      serving: "300 г",
      price: 250,
    },
    {
      name: "Борщ с говядиной",
      serving: "300 / 30 г",
      price: 350,
    },
    {
      name: "Лагман'",
      serving: "300 г",
      price: 350,
    },
    {
      name: "Солянка мясная",
      serving: "300 г",
      price: 380,
    },
    {
      name: "Суп харчо с говядиной",
      serving: "300 г",
      price: 420,
    },
    {
      name: "Крем-суп 'Курица-Грибы'",
      serving: "300 г",
      price: 370,
    },
    {
      name: "Окрошка с говядиной",
      description: "(квас, кефир)",
      serving: "350 г",
      price: 350,
    },
    {
      name: "Рамен (японский суп, классический острый",
      description: "Курица, лапша яичная, имбирь, овощной бульон, соус кимчи, лук зеленый",
      serving: "400 г",
      price: 550,
    },
    {
      name: "Фо-бо (вьетнамский суп)",
      description: "Говяжий бульон с добавлением корицы, аниса, вьетнамского кардамона. Подается с нарезанной говядиной, рисовой лапшой, зеленью, луком и соусом ширача, перцем чили, соевыми проростками, мятой, лаймом.",
      serving: "400 г",
      price: 590,
    },
  ],
  Хлеб: [
    {
      name: "Чиабатта", 
      serving: " ", 
      description: "(белая / серая)",
      price: 80,
    },
    {
      name: "Лепешка / Лаваш",
      serving: " ", 
      price: 80,
    }
  ],
  "Горячие блюда из мяса": [
    {
      name: "Стейк из филе говядины",
      serving: "за 100 г",
      price: 550,
    },
    {
      name: "Отбивная куриная",
      serving: "за 100 г",
      price: 200,
    },
    {
      name: "Цыпленок Табака",
      serving: "за 100 г",
      price: 230,
    },
    {
      name: "Чахохбили",
      serving: "300 г",
      price: 460,
    },
    {
      name: "Свинина с картофелем и помидорами, запеченная под сыром",
      serving: "350 г",
      price: 480,
    },
    {
      name: "Говядина с овощами",
      serving: "330 г",
      price: 700,
    },
    {
      name: "Свиные ребрышки, томленые в красном вине",
      serving: "300 г",
      price: 870,
    },
    {
      name: "Стейк из свинины",
      serving: "за 100 г",
      price: 270,
    },
    {
      name: "Котлета по-Киевски (с пюре)",
      serving: "130 / 100 г",
      price: 450,
    }
  ],
  "Горячие блюда из рыбы": [
    {
      name: "Ставрида",
      serving: "200 / 25 / 25 г",
      price: 320,
    },
    {
      name: "Барабуля",
      serving: "200 / 25 / 25 г",
      price: 480,
    },
    {
      name: "Луфарь",
      serving: "за 100 г",
      price: 350,
    },
    {
      name: "Камбала",
      serving: "за 100 г",
      price: 590,
    },
    {
      name: "Форель запеченая в фольге",
      serving: "за 100 г",
      price: 370,
    },
    {
      name: "Филе из судака",
      serving: "за 100 г",
      price: 430,
    },
    {
      name: "Котлеты из трески с картофельным пюре",
      serving: "150 / 100 г",
      price: 430,
    }
  ],
"Блюда на мангале": [
    {
      name: "Крыло куриное",
      serving: "за 100 г",
      price: 180,
    },
    {
      name: "Шашлык из филе курицы",
      serving: "за 100 г",
      price: 230,
    },
    {
      name: "Шашлык из говядины",
      serving: "за 100 г",
      price: 470,
    },
    {
      name: "Шампиньоны гриль",
      serving: "за 100 г",
      price: 150,
    },
    {
      name: "Дорадо",
      serving: "за 100 г",
      price: 380,
    },
    {
      name: "Овощи на мангале",
      serving: "за 100 г",
      price: 120,
    },
    {
      name: "Шея свиная",
      serving: "за 100 г",
      price: 280,
    },
    {
      name: "Люля-кебаб из курицы",
      serving: "150 г",
      price: 380,
    },
    {
      name: "Карэ ягненка",
      serving: "за 100 г",
      price: 640,
    },
    {
      name: "Форель радужная",
      serving: "за 100 г",
      price: 370,
    },
    {
      name: "Сибас",
      serving: "за 100 г",
      price: 380,
    },
    {
      name: "Семга (стейк)",
      serving: "за 100 г",
      price: 650,
    },
  ],
    Гарниры: [
    {
      name: "Картофель фри",
      serving: "150 г",
      price: 200,
    },
    {
      name: "Картофельное пюре",
      serving: "200 г",
      price: 200,
    },
    {
      name: "Картофель по-деревенски",
      serving: "150 г",
      price: 200,
    },
    {
      name: "Рис отварной",
      serving: "200 г",
      price: 200,
    },
  ],
  Пицца: [
    {
      name: "Маргарита",
      description: "(соус красный, моцарелла, помидоры, пармезан)",
      serving: "450 г",
      price: 430,
    },
    {
      name: "4 сыра",
      description:
        "(белый соус, моцарелла, радомер, дор блю, руккола, пармезан)",
      serving: "450 г",
      price: 580,
    },
    {
      name: "Пицца с курицей",
      description:
        "(соус красный, моцарелла, филе куриное, перец болгарский, сыр фета, руккола, пармезан)",
      serving: "480 г",
      price: 520,
    },
    {
      name: "Пицца с прошутто и рукколой",
      description:
        "(соус красный, моцарелла, вяленые томаты, прошутто, руккола, пармезан)",
      serving: "540 г",
      price: 550,
    },
    {
      name: "Пицца салями",
      description:
        "(соус красный, моцарелла, салями милано, оливки, руккола, пармезан)",
      serving: "550 г",
      price: 550,
    },
    {
      name: "Пицца Берлускони",
      description: "(соус красный, говядина, курица, бекон, моцарелла)",
      serving: "560 г",
      price: 600,
    },
    {
      name: "Пицца King",
      description: "(соус белый и красный, ветчина, грибы, ананас, помидор, моцарелла)",
      serving: "580 г",
      price: 580,
    },
    {
      name: "Пицца Диабло",
      description: "(соус красный и белый, ветчина, салями, помидор, грибы, моцарелла, перец чили)",
      serving: "560 г",
      price: 580,
    },
    {
      name: "Пицца Морская",
      description:
      "(соус красный и белый, креветки, мидии, кальмар, маслины, сыр)",
      serving: "570 г",
      price: 640,
    },
    {
      name: "Пицца Армения",
      description:
        "(соус красный, моцарелла, бастурма, говядина, лук ялтинский, острый красный перец)",
      serving: "580 г",
      price: 610,
    },
    {
      name: "Перец Чили",
      serving: "15 г",
      price: 50,
    },
    {
      name: "Соус Табаско",
      serving: "10 г",
      price: 50,
    },
  ],
      Хачапури: [
    {
      name: "Хачапури по-аджарски",
      serving: "250 г",
      price: 350,
    },
    {
      name: "Хачапури по-царски",
      serving: "600 г",
      price: 850,
    },
  ],
   Паста: [
    {
      name: "Рыбные пельмени с горбушей",
      serving: "240 / 30 г",
      price: 350,
    },
    {
      name: "Паста с курицей и грибами",
      serving: "300 г",
      price: 450,
    },
    {
      name: "Карбонара",
      serving: "300 г",
      price: 450,
    },
    {
      name: "Равиоли со шпинатами и рикоттой в сливочном соусе",
      serving: "250 / 50 г",
      price: 320,
    },
    {
      name: "Равиоли с индейкой в сливочном соусе",
      serving: "250 / 50 г",
      price: 420,
    },
    {
      name: "Вареники с картошкой, грибами и сметаной",
      serving: "250 / 30 г",
      price: 260,
    },
    {
      name: "Пельмени с мясом и сметаной",
      serving: "220 / 30 г",
      price: 320,
    },
    {
      name: "Рыбные пельмены с треской",
      serving: "240 / 30 г",
      price: 350,
    },
    {
      name: "Паста с креветками и трюфельным муссом",
      serving: "370 г",
      price: 590,
    },
    {
      name: "Равиоли с лососем и рикоттой в сливочном соусе",
      serving: "250 / 50 г",
      price: 480,
    },
    {
      name: "Пельмени жареные со сливками",
      serving: "250 / 50 г",
      price: 380,
    }
  ],
  Соусы: [
    {
      name: "Кетчуп",
      serving: "50 г",
      price: 70,
    },
    {
      name: "Соус армянский",
      serving: "50 г",
      price: 70,
    },
    {
      name: "Соус Чимичурри",
      serving: "50 г",
      price: 100,
    },
    {
      name: "Аджика грузинская",
      serving: "50 г",
      price: 100,
    },
    {
      name: "Чесночный",
      serving: "50 г",
      price: 70,
    },
    {
      name: "Тар-Тар",
      serving: "50 г",
      price: 70,
    },
    {
      name: "Соус сырный",
      serving: "50 г",
      price: 70,
    },
    {
      name: "Соус кисло-сладкий",
      serving: "50 г",
      price: 70,
    }
  ],
  "Суши и роллы": [
      {
      name: "Креветка темпура",
      description: "(Рис, нори, креветка, сливочный сыр, огурец)",
      serving: " 317г",
      price: 740,
      },
      {
      name: "Фила",
      description: "(Рис, нори, огурец, сливочный сыр, лосось)",
      serving: "275 г",
      price: 600,
      },
      {
      name: "Туна",
      description: "(Рис, нори, сливочный сыр, огурец, тунец)",
      serving: "275 г",
      price: 600,
      },
      {
      name: "Эби чиз",
      description: "(Рис, нори, креветка, сливочный сыр, огурец)",
      serving: "285 г",
      price: 650,
      },
      {
      name: "Ролл из лосося с сыром 'Филадельфия'",
      description: "(Рис, нори, сыр 'Филадельфия', икра форели, икра летучей рыбы, авокадо)",
      serving: "235 г",
      price: 1700,
      },
  ],
  Десерты: [
    {
      name: "Чизчейк",
      serving: "130 г",
      price: 320,
    },
    {
      name: "Штрудель яблочно-вишнёвый",
      serving: "130 / 50 г",
      price: 350,
    },
  ],
  Мороженое: [
    {
      name: "Мороженое",
      description: "(топпинг, тертый шоколад)",
      serving: "100 / 20 г",
      price: 250,
    },
    {
      name: "Мороженое с Бейлизом (100 / 20 г)",
      serving: "100 / 20 г",
      price: 350,
    },
    {
      name: "Мороженое шоколадное 'Рай'",
      description: "(мороженое шоколадное, топпинг шоколадный, вафельные трубочки, маршмеллоу, сливки)",
      serving: "100 / 30 / 30 / 30 г",
      price: 450,
    },
    {
      name: "Мороженое 'Инь-янь' ",
      description: "(белое морожное, шоколадный топпинг, сливки, шоколад, печенье 'Орео', кдровые орешки)",
      serving: "100 / 30 / 20 / 30 г",
      price: 450,
    },
    {
      name: "Мороженое 'Мармеладный каприз'",
      description: "(мороженое, сливки, мармелад, 'Орео')",
      serving: "100 / 50 / 30 / 20 г",
      price: 450,
    },
    {
      name: "Десерт 'Парадиз'",
      description: "(мороженое, фрукты, сливки, топпинг)",
      serving: "100 / 100 / 50 г",
      price: 440,
    },
  ],
  Фрукты: [
    {
      name: "Апельсин",
      serving: "1 шт",
      price: 150,
    },
    {
      name: "Лимон",
      serving: "1 шт",
      price: 100,
    },
    {
      name: "Яблоко",
      serving: "1 шт",
      price: 70,
    },
    {
      name: "Банан",
      serving: "1 шт",
      price: 100,
    },
    {
      name: "Киви",
      serving: "1 шт",
      price: 100,
    },
    {
      name: "Лайм",
      serving: "1 шт",
      price: 200,
    },
    {
      name: "Грейпфрут",
      serving: "1 шт",
      price: 350,
    },
  ],
  "Горячие напитки": [
    {
      name: "Эспрессо",
      serving: "50 мл",
      price: 120,
    },
    {
      name: "Американо",
      serving: "150 мл",
      price: 120,
    },
    {
      name: "Капучино",
      serving: "200 мл",
      price: 180,
    },
    {
      name: "Капучино на растительном молоке",
      descriptopn: "(овсяное, кокосовое, соевое)",
      serving: "200 мл",
      price: 250,
    },
    {
      name: "Капучино XL",
      descriptopn: "(овсяное, кокосовое, соевое)",
      serving: "300 мл",
      price: 270,
    },
    {
      name: "Латте",
      serving: "250 мл",
      price: 200,
    },
    {
      name: "Латте на растительном молоке",
      descriptopn: "(овсяное, кокосовое, соевое)",
      serving: "250 мл",
      price: 280,
    },
    {
      name: "Гляссе",
      serving: "250 мл",
      price: 250,
    },
    {
      name: "Какао",
      serving: "250 мл",
      price: 250,
    },
    {
      name: "Какао с маршмеллоу",
      serving: "250 мл",
      price: 300,
    },
    {
      name: "Чай в ассортименте",
      serving: "800 мл",
      price: 350,
    },
    {
      name: "Чай фруктовый",
      description: "(ягодный / облепиховый)",
      serving: "800 мл",
      price: 450,
    },
    {
      name: "Чай имбирный",
      serving: "800 мл",
      price: 450,
    },
    {
      name: "Молоко",
      serving: "50 мл",
      price: 30,
    },
    {
      name: "Молоко растительное",
      serving: "50 мл",
      price: 100,
    },
    {
      name: "Сливки",
      serving: "10 мл",
      price: 30,
    },
    {
      name: "Мёд",
      serving: "50 мл",
      price: 100,
    },
    {
      name: "Джем",
      serving: "50 мл",
      price: 100,
    },
  ],
  "Холодные напитки": [
    {
      name: "Кока-Кола",
      serving: "500 мл",
      price: 200,
    },
    {
      name: "Лимонад",
      serving: "500 мл",
      price: 250,
    },
    {
      name: "Лимонад домашний",
      description: "(цитрусовый / ягодный)",
      serving: "450 мл",
      price: 330,
    },
    {
      name: "Лимонад домашний",
      description: "(цитрусовый / ягодный)",
      serving: "1 л",
      price: 650,
    },
    {
      name: "Мохито Б/А",
      serving: "450 мл",
      price: 330,
    },
    {
      name: "Сок в ассортименте",
      serving: "200 мл",
      price: "90",
    },
    {
      name: "Сок в ассортименте",
      serving: "1 л",
      price: 450,
    },
    {
      name: "Компот в ассортименте",
      serving: "1 л",
      price: 550,
    },
    {
      name: "Минеральная вода",
      serving: "500 мл",
      price: 150,
    },
    {
      name: "Боржоми",
      serving: "500 мл",
      price: 300,
    },
    {
      name: "Джермук",
      serving: "500 мл",
      price: 250,
    },
    {
      name: "Ной (без газа)",
      serving: "500 мл",
      price: 250,
    }
  ],
  Фреш: [
    {
      name: "Яблоко",
      serving: "250 мл",
      price: 300,
    },
    {
      name: "Морковь",
      serving: "250 мл",
      price: 300,
    },
    {
      name: "Апельсин",
      serving: "250 мл",
      price: 350,
    },
    {
      name: "Грейпфрут",
      serving: "250 мл",
      price: 350,
    },
    {
      name: "Лимон",
      serving: "250 мл",
      price: 350,
    },
  ],
  Смузи: [
    {
      name: "Яблоко, апельсин, банан, киви, сок",
      serving: "350 мл",
      price: 450,
    },
    {
      name: "Смородина, вишня, клубника, сок",
      serving: "350 мл",
      price: 450,
    },
    {
      name: "Яблоко, огурец, сельдерей",
      serving: "350 мл",
      price: 450,
    },
    {
      name: "Банан, киви, молоко",
      serving: "350 мл",
      price: 450,
    },
    {
      name: "Банан, клубника, молоко",
      serving: "350 мл",
      price: 450,
    },
    {
      name: "Банан, вишня, молоко",
      serving: "350 мл",
      price: 450,
    },
    {
      name: "Банан, клюква, молоко",
      serving: "350 мл",
      price: 450,
    },
  ],
  Пиво: [
    {
      volume: {
        min: "300 мл",
        max: "500 мл",
      },
    },
    {
      name: "Шпатен 0.45 мл",
      description: "(светлое, немецкое)",
      serving: " ",
      price: 450,
    },
    {
      name: "Стелла 0.45 мл",
      description: "(светлое, безалкогольное)",
      serving: " ",
      price: 300,
    },
    {
      name: "Стелла 0.45 мл",
      description: "(светлое, алкогольное)",
      serving: " ",
      price: 300,
    },
    {
      name: "Крымская Ривьера",
      description: "(разливное, светлое)",
      price: {
        min: 150,
        max: 250,
      },
    },
    {
      name: "Белая скала",
      description: "(разливное, светлове, нефильтрованное)",
      price: {
        min: 150,
        max: 250,
      },
    },
    {
      name: "Черная гора",
      description: "(разливное, темное, нефильтрованное)",
      price: {
        min: 150,
        max: 250,
      },
    },
  ],
  "К пиву": [
    {
      name: "Фисташки",
      serving: "80 г",
      price: 380,
    },
    {
      name: "Креветка отварная",
      serving: "100 г",
      price: 250,
    },
  ],
  Шоты: [
    {
      name: "Б-52",
      description: "(Калуа, Бейлиз, Трипл сек)",
      serving: "60 мл",
      price: 380,
    },
    {
      name: "Хиросима",
      description: "(Самбука, Бейлиз, Абсент, Гренадин)",
      serving: "60 мл",
      price: 430,
    },
    {
      name: "Зеленый мексиканец",
      description: "(Пизан, фреш лимонный, Текила)",
      serving: "60 мл",
      price: 350,
    },
    {
      name: "Бездна",
      description: "(текила 'Бланко', 'Блю кюрасао', сливочный ликер, гренадин)",
      serving: "60 мл",
      price: 380,
    }
  ],
  Коктейли: [
    {
      name: "Лонг-Айленд",
      description: "(Ром, Текила, водка, Джин, кола, Трипл сек)",
      serving: "450 мл",
      price: 700,
    },
    {
      name: "Мохито",
      description: "(ром, лайм, мята, Спрайт)",
      serving: "450 мл",
      price: 540,
    },
    {
      name: "Голубая лагуна",
      description: "(водка, Малибу, Блю кюрасао, Спрайт)",
      serving: "350 мл",
      price: 470,
    },
    {
      name: "Текила Санрайз",
      description: "(текила, сок апельсиноый, Гренадин)",
      serving: "350 мл",
      price: 580,
    },
    {
      name: "Гаваи",
      description: "(ром, Малибу, сок ананасовый, Блю кюрасао)",
      serving: "350 мл",
      price: 580,
    },
    {
      name: "Джин Тоник",
      description: "(ром, Малибу, сок ананасовый, Блю кюрасао)",
      serving: "350 мл",
      price: 480,
    },
    {
      name: "Виски кола",
      description: "(виски, кола)",
      serving: "350 мл",
      price: 480,
    },
    {
      name: "Черный русский",
      description: "(водка, калуа)",
      serving: "350 мл",
      price: 500,
    },
    {
      name: "Секс на пляже",
      description: "(водка, персиковый ликер, сок клюквенный, апельсин)",
      serving: "350 мл",
      price: 500,
    },
    {
      name: "Глинтвейн",
      description: "(сухое красное вино, мед, специи)",
      serving: "200 мл",
      price: 350,
    },
    {
      name: "Самбука",
      description: "(Самбука, кофейные зерна)",
      serving: "50 мл",
      price: 400,
    },
    {
      name: "Апероль Сауэр",
      description: "(джин, Апероль, сок лимонный, сахарный сироп)",
      serving: "180 мл",
      price: 650,
    },
    {
      name: "Апероль Шприц",
      description: "(просекко, апероль, газировка, апельсин)",
      serving: "180 мл",
      price: 650,
    },
    {
      name: "Мартини Санрайз",
      description: "(Мартини, джин, сок апельсиновый, гренадин)",
      serving: "350 мл",
      price: 570,
    },
    {
      name: "Мимоза",
      description: "(Шампанское брют, апельсиновый сок)",
      serving: "300 мл",
      price: 480,
    }
  ],
  "Элитные напитки": [
    {
      volume: {
        max: "100 мл"
      },
    },
    {
      name: "Текила Diego & Maria",
      serving: " ",
      price: 650
    },
    {
      name: " Джин 'Бифитер'",
      serving: " ",
      price: 600
    },
    {
      name: "Ром Carta Vieja",
      description: "(белый, черный)",
      serving: " ",
      price: 600
    },
    {
      name: "Абсент",
      serving: " ",
      price: 700
    },
    {
      name: "Мартини Бьянко",
      serving: " ",
      price: 400
    },
    {
      name: "Бейлиз",
      serving: " ",
      price: 550
    },    {
      name: "Бехеровка",
      serving: " ",
      price: 500
    },
  ],
  "Виски": [
   {
      volume: {
        max: "100 мл"
      },
    },
    {
      name: "Чивас",
      serving: " ",
      price: 1200
    },
    {
      name: "Блек Лейбл",
      serving: " ",
      price: 1200
    },
    {
      name: "Джек Дениелс",
      serving: " ",
      price: 1000
    },
    {
      name: "Джемесон",
      serving: " ",
      price: 900
    },
    {
      name: "Балантайсл",
      serving: " ",
      price: 700
    },
    {
      name: "Бонкерс",
      serving: " ",
      price: 350
    },
  ], 
  "Коньяк": [
    {
      volume: {
        min: "100 мл",
        max: "500 мл"
      }
    },
    {
      name: "Старый Крым 3*",
      price: {
        min: 360,
        max: 1800,
      }
    },
    {
      name: "Черный дуб 4*",
      price: {
        min: 380,
        max: 1900,
      }
    },
    {
      name: "Старый крым 5*",
      price: {
        min: 400,
        max: 2000,
      }
    }, 
    {
      name: "Коктебель 3*",
      price: {
        min: 340,
        max: 1700,
      }
    }, 
    {
      name: "Коктебель 5* Резерв",
      price: {
        min: 500,
        max: 2500,
      }
    }, 
    {
      name: "Арарат (3 года)",
      price: {
        min: 600,
        max: 3000,
      }
    }, 
    {
      name: "Арарат (5 лет)",
      price: {
        min: 700,
        max: 3500,
      }
    }, 
    {
      name: "Ани (6 лет)",
      price: {
        min: 800,
        max: 4000,
      }
    }, 
    {
      name: "Ахтамар (10 лет)",
      price: {
        min: 1400,
        max: 7000,
      }
    },
    {
      name: "Мартель VS",
      price: {
        min: 1400,
        max: 7000,
      }
    }
  ], 
  "Инкерман": [
    {
      volume: {
        min: "100 мл",
        max: "1 бут."
      }
    },
       {
      name: "Алиготе",
      description: "(белое, сухое)",
      price: {
        min: 180,
        max: 1350,
      },
    },
    {
      name: "Легенда полуострова",
      description: "(белое, полусладкое)",
      price: {
        min: 180,
        max: 1350,
      },
    },
    {
      name: "Древний город",
      description: "(красное, полусладкое",
      price: {
        min: 180,
        max: 1350,
      },
    },
    {
      name: "Инкерман",
      description: "(белое / красное, полусухое)",
      price: {
        min: 180,
        max: 1350,
      },
    },
    {
      name: "Жемчужина Резерв",
      description: "(белое, сухое)",
      price: {
        min: 200,
        max: 1500,
      },
    },
       {
      name: "Каберне Резерв",
      description: "(красное, сухое)",
      price: {
        min: 220,
        max: 1650,
      },
    }
  ], 
  "Вилла Крым": [
    {
      volume: {
        min: "100 мл",
        max: "1 бут."
      }
    },
    {
      name: "Шато Барон",
      description: "(белое, полусладкое)",
      price: {
        min: 160,
        max: 1200,
      },
    },
    {
      name: "Шевалье Руж",
      description: "(красное, полусладкое)",
      price: {
        min: 160,
        max: 1200,
      },
    },
    {
      name: "Мускат Ривьера",
      description: "(розовое, полусладкое)",
      price: {
        min: 170,
        max: 1275,
      },
    },
    {
      name: "Шардоне",
      description: "(белое, сухое)",
      price: {
        min: 160,
        max: 1200,
      },
    },
        {
      name: "Каберне",
      description: "(красное, сухое)",
      price: {
        min: 170,
        max: 1275,
      },
    }
  ],
    "Вина Грузии": [
    {
      volume: {
        min: "100 мл",
        max: "1 бут."
      }
    },
    {
      name: "Хванчкара",
      description: "(красное, полусладкое)",
      price: {
        min: 460,
        max: 3450,
      },
    },
    {
      name: "Киндзмараули",
      description: "(красное, полусладкое)",
      price: {
        min: 330,
        max: 2475,
      },
    },
    {
      name: "Алазанская долина",
      description: "(белое / красное, полусладкое)",
      price: {
        min: 200,
        max: 1500,
      },
    },
    {
      name: "Мукузани",
      description: "(красное, сухое)",
      price: {
        min: 300,
        max: 2250,
      },
    },
      {
      name: "Саперави",
      description: "(красное, сухое)",
      price: {
        min: 250,
        max: 1875,
      },
    },
    {
      name: "Цинандали",
      description: "(белое, сухое)",
      price: {
        min: 250,
        max: 1875,
      },
    }
  ],
  "Водка": [
    {
      volume: {
        min: "100 мл",
        max: "500 мл"
      }
    },
    {
      name: "Немиров Оригинал",
      price: {
        min: 180,
        max: 900,
      },
    },
    {
      name: "Царская Оригинал",
      price: {
        min: 300,
        max: 1500,
      },
    },
    {
      name: "Абсолют",
      price: {
        min: 500,
        max: 2500,
      },
    },
    {
      name: "Финляндия",
      price: {
        min: 550,
        max: 2750,
      },
    },
      {
      name: "Граппа",
      price: {
        min: 350,
        max: 1750,
      },
    }
  ], 
  "Игристые вина": [
   {
      volume: {
        max: "1 бут."
      },
    },
    {
      name: "Новый свет",
      description: "(белое брют, полусухое, полусладкое)",
      serving: " ",
      price: 1800
    },
    {
      name: "Frizzante",
      description: "(белое, сухое)",
      serving: " ",
      price: 1300
    },
    {
      name: "Frizzante",
      description: "(розовое, полусухое)",
      serving: " ",
      price: 1300
    },
    {
      name: "Moscato",
      description: "(белое, полусладкое)",
      serving: " ",
      price: 1300
    },
    {
      name: "Инкерман",
      description: "(белое / розовое брют / полусладкое)",
      serving: " ",
      price: 1200
    }
  ],
  "ZB Wine": [
    {
      volume: {
        min: "100 мл",
        max: "1 бут."
      }
    },
    {
      name: "Локо Чимбали Оранж",
      description: "(белое, сухое)",
      price: {
        min: 400,
        max: 3000,
      },
    },
    {
      name: "Локо Чимбали Пино Менье",
      description: "(красное, сухое)",
      price: {
        min: 400,
        max: 3000,
      },
    },
    {
      name: "Совиньон Блан",
      description: "(белое, сухое)",
      price: {
        min: 200,
        max: 1500,
      },
    },
    {
      name: "Розе",
      description: "(розовое, сухое)",
      price: {
        min: 200,
        max: 1500,
      },
    },
      {
      name: "Рислинг",
      description: "(белое, полусухое)",
      price: {
        min: 200,
        max: 1500,
      },
    },
      {
      name: "Мусккт",
      description: "(белое, полусладкое)",
      price: {
        min: 200,
        max: 1500,
      },
    },
      {
      name: "Бастардо",
      description: "(красное, полусладкое)",
      price: {
        min: 200,
        max: 1500,
      },
    }
  ],
  "Массандра": [
    {
      volume: {
        min: "100 мл",
        max: "1 бут."
      }
    },
    {
      name: "Херес",
      description: "(белое, сухое / крепленое)",
      price: {
        min: 240,
        max: 1800,
      },
    },
    {
      name: "Мадера",
      description: "(белое, крепленое)",
      price: {
        min: 240,
        max: 1800,
      },
    },
    {
      name: "Портвейн",
      description: "(белое / красное, крепленое)",
      price: {
        min: 200,
        max: 1500,
      },
    },
    {
      name: "Мускатель",
      description: "(белое, розовое, черное, десертное)",
      price: {
        min: 180,
        max: 1350,
      },
    },
      {
      name: "Мускат БКК",
      description: "(белое, десертное)",
      price: {
        min: 280,
        max: 2100,
      },
    },
      {
      name: "Кагор",
      description: "(белое, десертное)",
      price: {
        min: 200,
        max: 1500,
      },
    },
      {
      name: "Бастардо",
      description: "(красное, десертное)",
      price: {
        min: 240,
        max: 1800,
      },
    },
      {
      name: "Жемчужина Массандры",
      description: "(белое / красное, полусладкое)",
      price: {
        min: 160,
        max: 1200,
      },
    },
      {
      name: "Алушта",
      description: "(красное, сухое)",
      price: {
        min: 240,
        max: 1800,
      },
    }
  ]
}
